<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Igazi-e a digitális fájdalom? 
          </h2>
        </b-col>
      </b-row>

      <b-row class="maintText__row">
        <b-col cols="12" lg="6" class="side-gap order-0">
          <div class="maintText__txt-content">
            <p class="text">
              Ami az interneten történik, az bizony örökre megmarad. Szárnyra kel és pillantok alatt kontinenseket hódíthat meg, de legalábbis könnyen juthat a szomszéd fülébe vagy éppen a családtagjainkhoz olyan információ, amit vagy nem szerettünk volna megosztani, vagy nem is igaz. </p>
            <p class="text pb-5">Könnyű lenne azt mondani, hogy a cyberbullying megáll a kikapcsolt képernyőnél, ezáltal kevesebb vizet kavar, mint amikor az iskolában, vagy munkahelyen esik áldozatul az ember bántalmazásnak. Pedig pont az ellenkezőjéről van szó: az online bántalmazás nem ér véget azon a helyszínen, ahol valójában történik, hanem egyszerre van jelen mindenhol, korlátlan mennyiségű szereplővel, korlátlan ideig. Egyszerre gyors és válaszképes, folyton változó és örök nyomot hagyó: ha feltöltünk egy képet például, az nem körbemutogatva lesz, hanem szétküldve embereknek, akik továbbküldik, így ágrajzként terjeszkedik. <br><br>Az internet valósága: 
            </p>
              
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-4 order-lg-1">
            <img src="@/assets/images/bgr/pain.jpg" class="w-100 margin-top-sm-44"/>
        </b-col>
        <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Valós fájdalom:</b> A kirekesztés olyan agyi folyamatokat indít be, mint a fizikai fájdalom. Az ember társas élőlény, az agyunk tehát a büntetésnek szánt magányt veszélyforrásként kezelheti. </p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>A bántalmazás tünetei:</b> A stressz és a trauma képes fizikai tüneteket okozni a szervezetben. Ezek leggyakrabban két területre, a fejre és a hasra összpontosulnak, így a fejfájás, szédülés, hányinger, émelygés, gyomorfájdalom, étvágytalanság, rossz közérzet, de még a fáradékonyság is jelezheti, hogy nem megfelelő mentális állapotban vagyunk. Ezt erősíti, ha az étvágytalanságból fakadóan az étkezésünk is megváltozik, vagy éppen a fejfájás vagy a nyughatatlanság miatt alvásproblémák is felmerülnek. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Örök sérelmek:</b> Főként gyerekeknél, serdülőknél jellemző az a veszély, hogy a fejlődésben lévő agyukat áthuzalozza egy olyan bántalmazás, ami miatt félénkebbek, csendesebbek, visszahúzódóbbak, óvatosak lesznek védekezésül. Ennek akár az ellentéte is bekövetkezhet, önpusztító, hangos és agresszív viselkedéssel. Ami leginkább sérül azonban, az az énkép: nehéz kilépni az áldozat szerepéből, főleg, ha huzamosabb ideig ezt erősítették bennünk a kortársaink, és ez alapján alakult ki az identitásunk.</p>
                </li>
              </ul>
            </p>
            <p class="text">
                Az internet nem csupán egy veszélyes, végletekig romlott hely. Számos támogató oldal, csoport létezik, ahol a világ minden tájáról megtalálhatják egymást a hasonló tapasztalatukat megosztó emberek, ezzel segítve a továbblépést, felépülést. 
            </p>
        </b-col>
      </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Igazi-e a digitális fájdalom?", 
        desc: "Ami az interneten történik, az bizony örökre megmarad. Szárnyra kel és pillantok alatt kontinenseket hódíthat meg, de legalábbis könnyen juthat a szomszéd fülébe vagy éppen a családtagjainkhoz olyan információ, amit vagy nem szerettünk volna megosztani, vagy nem is igaz.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Igazi-e a digitális fájdalom?",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Ami az interneten történik, az bizony örökre megmarad. Szárnyra kel és pillantok alatt kontinenseket hódíthat meg, de legalábbis könnyen juthat a szomszéd fülébe vagy éppen a családtagjainkhoz olyan információ, amit vagy nem szerettünk volna megosztani, vagy nem is igaz."        
    })
  }
}
</script>